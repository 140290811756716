@import url("https://fonts.googleapis.com/css2?family=Oswald&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dosis&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poiret+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Catamaran:wght@300&family=Poiret+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Hammersmith+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

@media only screen and (max-width: 850px) {
    body .telts h1 {
        font-size: calc(var(--rem1) * 1.8);
    }
}

@media only screen and (max-width: 775px) {
    body .telts h1 {
        font-size: calc(var(--rem1) * 1.5);
    }
}
@media only screen and (max-width: 675px) {
    body .telts h1 {
        font-size: calc(var(--rem1) * 1.4);
    }
}
@media only screen and (max-width: 768px) {
    body .telts {
        width: 90%;
        display: block;
        margin: auto;
        margin-top: var(--rem1);
        margin-bottom: var(--rem1);
    }
    body .telts h1 {
        font-size: calc(var(--rem1) * 3);
    }
    body .telts p {
        font-size: calc(var(--rem1) * 1.5);
    }
    body .serv {
        display: block;
    }
}

.servcont {
    width: 100%;
    text-align: center;
    margin: auto;
    padding-top: var(--rem1);
    margin-bottom: var(--rem2);
    overflow: hidden;
}

.servcont * {
    overflow: hidden;
}

.servcont h1 {
    font-size: var(--rem3);
    color: var(--black2);
    display: block;
    margin: auto;
    margin-top: var(--rem1);
    margin-bottom: var(--rem2);
    font-family: "El Messiri", sans-serif;
    color: white;
}

.serv {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.telts {
    width: 30%;
    height: max(calc(var(--rem5) * 4), max-content);
    margin: var(--rem05);
}

.telts div {
    background-color: rgba(255, 255, 255, 0.02);
}

.telts div > div {
    padding: var(--rem05);
}

.telts h1 {
    font-family: "El Messiri", sans-serif;
    font-size: var(--rem2);
    color: #ffbf0f;
    margin: var(--rem05);
}

.telts p {
    font-family: "El Messiri", sans-serif;
    font-size: calc(var(--rem1) * 1.2);
    text-transform: capitalize;
    text-align: left;
    color: white;
}

.telts ul {
    font-family: sans-serif;
    font-size: calc(var(--rem1) * 1.2);
    text-transform: capitalize;
    text-align: left;
    text-align-last: left;
    color: var(--black2);
}

.telts li {
    margin-top: var(--rem1);
}

.telts img {
    width: 15%;
}
