@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lemonada:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Marhey&display=swap");

@import url("https://fonts.googleapis.com/css2?family=El+Messiri:wght@500&display=swap");

@media only screen and (max-width: 820px) {
    body .asubthrd p {
        font-size: calc(var(--rem1) * 1.5);
        text-align: center;
        display: block;
        margin-top: var(--rem1);
        margin-bottom: var(--rem1);
    }
    body .asubthrd {
        margin-top: 0;
        margin-bottom: 0;
        text-align: center;
    }
    body .asubthrd div {
        display: block;
        margin: auto;
    }
}
@media only screen and (max-width: 650px) {
    body .asubthrd p {
        font-size: calc(var(--rem1) * 1.5);
        text-align: center;
        display: block;
        margin-top: var(--rem1);
        margin-bottom: var(--rem1);
    }
    body .asubthrd {
        margin-top: 0;
        margin-bottom: 0;
        text-align: center;
    }
    body .asubthrd div {
        display: block;
        margin: auto;
    }
    body .asnd ul {
        font-size: var(--rem1);
    }
}
@media only screen and (max-width: 455px) {
    body .asubthrd p {
        font-size: calc(var(--rem1) * 1.5);
        text-align: center;
        display: block;
        margin-top: var(--rem1);
        margin-bottom: var(--rem1);
    }
    body .asubthrd {
        margin-top: 0;
        margin-bottom: 0;
        text-align: center;
    }
    body .asubthrd div {
        display: block;
        margin: auto;
    }
    body .asnd ul {
        font-size: var(--rem1);
        display: block;
        max-width: 90%;
        margin: var(--rem1);
    }
    body .asnd li {
        width: 90%;
        text-align: justify;
    }
    body .asnd {
        display: block;
        text-align: center;
    }
    body .asnd img {
        max-width: 70%;
    }
    body .aaboutcont,
    body .ax8 {
        width: 100%;
    }
}

.aaboutcont {
    direction: rtl;
    width: 95%;
    margin: auto;
    margin-top: var(--rem1);
    margin-bottom: var(--rem2);
    color: white;
    overflow: hidden;
}

.ax8 {
    width: 80%;
}

.afirst {
    max-width: 95%;
    margin: auto;
    margin-top: var(--rem1);
    margin-bottom: var(--rem2);
}

.afirst h1 {
    font-family: "El Messiri", sans-serif;
    text-shadow: calc(var(--rem05) * 0.6) calc(var(--rem05) * 0.6)
        calc(var(--rem05) * 0.5) black;
    display: block;
    margin: auto;
    text-align: center;
    font-size: var(--rem35);
    color: #ffbf0f;
}

.afirst p {
    font-family: "El Messiri", sans-serif;
    text-align: justify;
    text-align-last: center;
    font-size: calc(var(--rem1) * 1.1);
    text-transform: capitalize;
}

.asnd {
    width: 100%;
    margin: auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    margin-top: var(--rem1);
    margin-bottom: var(--rem2);
}

.asnd ul {
    max-width: 45%;
    font-family: "Marhey", cursive;
    display: inline-block;
    font-size: calc(var(--rem1) * 1.4);
    text-transform: capitalize;
    font-weight: 700;
    list-style-type: disc;
    margin: var(--rem05);
}

.asnd li {
    margin-top: var(--rem1);
}

.asnd img {
    display: inline-block;
    max-width: 40%;
    border-radius: 2%;
    border: 1px solid black;
    box-shadow: 3px 3px 5px black;
}

.athrd {
    width: 90%;
    margin: auto;
    text-align: center;
    margin-top: var(--rem1);
    margin-bottom: var(--rem3);
}

.athrd h1 {
    font-family: "El Messiri", sans-serif;
    text-shadow: calc(var(--rem05) * 0.5) calc(var(--rem05) * 0.5)
        calc(var(--rem05) * 0.5) black;
    display: block;
    margin: auto;
    text-align: center;
    font-size: var(--rem35);
    color: #ffbf0f;
    margin-top: var(--rem1);
    margin-bottom: var(--rem2);
}
.asubthrd {
    width: 100%;
    margin: auto;
    text-align: right;
    margin-top: var(--rem1);
    margin-bottom: var(--rem2);
}

.asubthrd div {
    display: block;
    width: 100%;
    margin: auto;
}

.asubthrd p {
    text-align: right;
    font-size: calc(var(--rem1) * 1.5);
    font-family: "El Messiri", sans-serif;
    color: #ffbf0f;
    margin: var(--rem1);
    display: inline;
    text-transform: capitalize;
    text-shadow: calc(var(--rem05) * 0.2) calc(var(--rem05) * 0.2) black;
}
