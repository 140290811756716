@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lemonada:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Marhey&display=swap");

@import url("https://fonts.googleapis.com/css2?family=El+Messiri:wght@500&display=swap");

@media only screen and (max-width: 850px) {
    body .atelt h1 {
        font-size: calc(var(--rem1) * 1.8);
    }
}
@media only screen and (max-width: 807px) {
    body .aadv {
        width: 100%;
        box-sizing: border-box;
    }
    body .aadvgrid p {
        width: 95%;
        text-align: center;
        font-size: calc(var(--rem1) * 1.2);
    }
    body .aadvgrid {
        text-align: center;
    }
    body .atelt img {
        width: 85%;
    }
}
@media only screen and (max-width: 775px) {
    body .atelt h1 {
        font-size: calc(var(--rem1) * 1.5);
    }
    body .aidea {
        width: 80%;
    }
}
@media only screen and (max-width: 675px) {
    body .atelt h1 {
        font-size: calc(var(--rem1) * 1.4);
    }
    body .atelt img {
        width: 90%;
    }
}
@media only screen and (max-width: 768px) {
    body .atelt {
        width: 90%;
    }
    body .atelt h1 {
        font-size: calc(var(--rem1) * 3);
    }
    body .atelt p {
        font-size: calc(var(--rem1) * 1.5);
        text-align: center;
    }
    body .atelt img {
        width: 70%;
    }
    body .awhyus {
        display: block;
    }
    body .avismis {
        display: block;
    }
    body .anos,
    body .anos1,
    body .anos2 {
        margin: auto;
        width: 90%;
        padding: var(--rem1);
    }
    body .anos img {
        float: left;
        margin: var(--rem1);
    }
    body .anos2 img {
        transform: none;
        display: block;
        margin: auto;
        text-align: center;
    }
}


.a {
    direction: rtl;
}

.awhyuscont {
    width: 100%;
    text-align: center;
    margin: auto;
    padding-top: var(--rem1);
    margin-bottom: var(--rem2);
    overflow: hidden;
}

.awhyuscont h1 {
    font-size: var(--rem3);
    color: var(--black2);
    display: block;
    margin: auto;
    margin-top: var(--rem1);
    margin-bottom: var(--rem2);
    font-family: "El Messiri", sans-serif;
    color: white;
}

.awhyus {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
}

.atelt {
    width: 32%;
    padding: var(--rem05);
    display: inline-block;
}

.atelt img {
    width: 80%;
    display: block;
    margin: auto;
    border: 1px dashed goldenrod;
    border-radius: 5%;
}

.atelt h1 {
    font-family: "El Messiri", sans-serif;
    font-size: var(--rem2);
    color: #ffbf0f;
    margin: var(--rem05);
}

.atelt p {
    font-family: "El Messiri", sans-serif;
    font-size: calc(var(--rem1) * 1.2);
    text-transform: capitalize;
    text-align: right;
    padding: var(--rem05);
    font-weight: 300;
    color: white;
}

.avismis {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    text-align: center;
    overflow: hidden;
}

.anos {
    width: 40%;
}

.anos img {
    width: 15%;
    height: 15%;
    display: block;
    margin: auto;
    margin-bottom: var(--rem2);
}

.anos h1 {
    font-family: "El Messiri", sans-serif;
    font-size: var(--rem2);
    color: #ffbf0f;
    margin: var(--rem5);
    display: block;
    margin: auto;
}

.anos p {
    font-family: "El Messiri", sans-serif;
    font-size: calc(var(--rem1));
    text-transform: capitalize;
    text-align: justify;
    font-weight: 300;
    color: white;
}

.anos1 {
    width: 55%;
}
.anos2 {
    width: 35%;
}

.anos2 img {
    width: 100%;
    height: calc(var(--rem5) * 5);
    transform: translateY(50%);
}
.anos1 img {
    width: 15%;
    height: 15%;
    display: block;
    margin: auto;
    margin-bottom: var(--rem2);
}

.anos1 h1 {
    font-family: "El Messiri", sans-serif;
    font-size: var(--rem3);
    color: #ffbf0f;
    text-align: right;
    margin: 0;
}

.anos1 p {
    font-family: "El Messiri", sans-serif;
    font-size: calc(var(--rem105));
    text-transform: capitalize;
    text-align: right;
    font-weight: 300;
    color: white;
}



.aidea {
    width: 60%;
    margin: auto;
    margin-top: var(--rem1);
    margin-bottom: var(--rem2);
    overflow: hidden;
}

.aidea img {
    width: 100%;
    object-fit: contain;
    display: block;
    margin: auto;
    padding: 1px;
    border: 1px dashed goldenrod;
}

.aidea p {
    font-size: var(--rem105);
    font-family: "Marhey", cursive;
    font-weight: 900;
    text-align: justify;
    max-width: 90%;
    display: block;
    margin: auto;
    color: white;
}

.aidea b {
    display: inline;
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
        "Lucida Sans", Arial, sans-serif;
    font-size: var(--rem2);
    color: #ffbf0f;
}

.aadvcont {
    overflow: hidden;
}
.aadvcont h1 {
    text-align: center;
    font-family: "El Messiri", sans-serif;
    font-size: var(--rem3);
    color: var(--black2);
    color: white;
}

.aadv {
    width: 80%;
    margin: auto;
    margin-top: var(--rem1);
    margin-bottom: var(--rem2);
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    text-align: justify;
}

.aadvcont > p {
    font-size: calc(var(--rem1) * 1.2);
    font-family: "El Messiri", sans-serif;
    font-weight: 300;
    width: 80%;
    margin: auto;
    display: block;
    color: white;
}

.aadv > p {
    font-size: calc(var(--rem1) * 1.2);
    font-family: "El Messiri", sans-serif;
    font-weight: 300;
    width: 55%;
    margin-left: 5%;
    margin-right: 5%;
    color: white;
}
.aadvgrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    width: 80%;
    gap: 5px;
    font-size: var(--rem1);
}

.aadvgrid img {
    width: 30%;
    display: inline;
    vertical-align: top;
    margin: var(--rem1);
}

.aadvgrid div {
    margin: auto;
    width: 100%;
}

.aadvgrid p {
    display: inline-block;
    width: 60%;
    font-family: "El Messiri", sans-serif;
    font-weight: 300;
    text-align: right;
    color: var(--black2);
    color: white;
}
