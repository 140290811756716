@import url("https://fonts.googleapis.com/css2?family=Oswald&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dosis&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poiret+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Catamaran:wght@300&family=Poiret+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Hammersmith+One&display=swap");

@media only screen and (min-width: 2000px) {
    :root {
        --rem05: 1rem;
        --rem1: 2rem;
        --rem105: 3rem;
        --rem2: 4rem;
        --rem3: 6rem;
        --rem35: 7rem;
        --rem4: 8rem;
        --rem5: 6rem;
    }
}
@media only screen and (max-width: 1999px) {
    :root {
        --rem05: 0.75rem;
        --rem1: 1.5rem;
        --rem105: 2.25rem;
        --rem2: 3rem;
        --rem3: 4.5rem;
        --rem35: 5rem;
        --rem4: 6rem;
        --rem5: 7.5rem;
    }
}
@media only screen and (max-width: 1620px) {
    :root {
        --rem05: 0.5rem;
        --rem1: 1rem;
        --rem105: 1.5rem;
        --rem2: 2rem;
        --rem3: 3rem;
        --rem35: 3.5rem;
        --rem4: 4rem;
        --rem5: 5rem;
    }
}
@media only screen and (max-width: 1365px) {
    :root {
        --rem05: 0.4rem;
        --rem1: 0.8rem;
        --rem105: 1.2rem;
        --rem2: 1.6rem;
        --rem3: 2.4rem;
        --rem35: 2.8rem;
        --rem4: 3.2rem;
        --rem5: 4rem;
    }
}
@media only screen and (max-width: 807px) {
    :root {
        --rem05: 0.4rem;
        --rem1: 0.8rem;
        --rem105: 1.2rem;
        --rem2: 1.6rem;
        --rem3: 2.4rem;
        --rem35: 2.8rem;
        --rem4: 3.2rem;
        --rem5: 4rem;
    }
}
@media only screen and (max-width: 650px) {
    :root {
        --rem05: 0.3rem;
        --rem1: 0.65rem;
        --rem105: 0.85rem;
        --rem2: 1.2rem;
        --rem3: 1.8rem;
        --rem35: 2.1rem;
        --rem4: 2.6rem;
        --rem5: 3rem;
    }
}
@media only screen and (max-width: 450px) {
    :root {
        --rem05: 0.3rem;
        --rem1: 0.65rem;
        --rem105: 0.85rem;
        --rem2: 1.2rem;
        --rem3: 1.8rem;
        --rem35: 2.1rem;
        --rem4: 2.6rem;
        --rem5: 3rem;
    }
    body .right p::before {
        content: "\00000a";
        white-space: pre;
    }
    body .right p::after {
        content: "\00000a";
        white-space: pre;
    }
}

:root {
    --black: rgb(26, 24, 24);
    --black2: rgb(48, 43, 43);
    --gold: rgb(219, 216, 28);
}

.prf {
    align-items: flex-start;
    justify-content: space-evenly;
    border: 2px dashed #ffbf0f;
    width: 80%;
    margin: auto;
    margin-top: var(--rem1);
    margin-bottom: var(--rem2);
    padding: var(--rem05);
    color: white;
}

.left {
    width: 100%;
}

.left p {
font-family: 'El Messiri', sans-serif;
    font-weight: bold;
    font-size: calc(var(--rem1) * 1.2);
    text-transform: capitalize;
    text-align: center;
}

.left h1 {
    font-size: var(--rem3);
    color: #ffbf0f;
    text-align: center;
    margin-top: var(--rem1);
    margin-bottom: var(--rem2);
font-family: 'El Messiri', sans-serif;
    font-style: italic;
    font-weight: 600;
}

.right {
    width: 100%;
    text-align: center;
    text-transform: capitalize;
}

.right h1 {
    font-size: var(--rem3);
    color: #ffbf0f;
    text-align: center;
    margin-top: var(--rem1);
    margin-bottom: var(--rem1);
font-family: 'El Messiri', sans-serif;
    font-style: italic;
    font-weight: 600;
}

.right span {
    border-radius: 5%;
font-family: 'El Messiri', sans-serif;
    background: #ffbf0f;
    padding: var(--rem1);
    display: inline-block;
    font-size: var(--rem2);
    cursor: pointer;
    margin-left: var(--rem1);
    margin-right: var(--rem1);
    text-transform: capitalize;
    text-decoration: none;
    color: black;
}

.right span:hover {
    background: gold;
}
.right p {
    display: inline;
    font-family: cursive;
    font-size: calc(var(--rem1) * 1.2);
    text-transform: capitalize;
    text-align: center;
}
